import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import "./layout.css"
import "../css/fonts.css"

// TODO: Do something better to get the lacale
const Layout = ({ children, locale }) => {
  const { rawData } = useStaticQuery(query)

  const formatted = rawData.edges.map(item => {
    return {
      name: item.node.name,
      translations: item.node.translations,
      link: item.node.name === 'en' ? '/id' : '/'
    }
  })

  const data = formatted.filter(lang => lang.name === locale)[0]

  const translations = data ? data.translations : {}
  const link = data ? data.link : ""

  const Wrapper = ({ children }) => (
    <>
      <div className='body'>
        <Header { ...translations } locale={locale} />
        {children}
      </div>
      <Footer { ...translations } link={link} />
    </>
  )

  return (
    <div className='layout'>
      <Wrapper>{children}</Wrapper>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const query = graphql`
  query Layout {
    rawData: allFile(filter: { sourceInstanceName: { eq: "translations" } }) {
      edges {
        node {
          name
          translations: childTranslationsJson {
            footer
            language
            body
            apple,
            google
          }
        }
      }
    }
  }
`