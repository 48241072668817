import React from "react"
import "../css/footer.css"
import Link from 'gatsby-link';
import { isIOS, isAndroid } from "react-device-detect";

const Footer = ({ footer, language, link, apple, google }) => {

  const legalText = () => {
    if (isIOS) return <p>{apple}</p>
    if (isAndroid) return <p>{google}</p>

    return (
      <>
        <p>{apple}</p>
        <p>{google}</p>
      </>
    )
  }

  return (
    <>
      <div className='footer-wrap'>
        <footer>
          <p>{footer} <Link className='uderline' to={link}>{language}</Link></p>
          <p>&copy; 2019 <a className='gojek' href='https://gojek.com'>Gojek</a></p>
        </footer>
      </div>
      <div className='legal'>
        { legalText() }
      </div>
    </>
  )
}



export default Footer