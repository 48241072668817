import React from "react"
import PropTypes from "prop-types"
import { isIOS, isAndroid } from "react-device-detect";

import "../css/header.css"
import logo from "../images/logo.svg"

import appStoreEN from "../images/appstore/app-store-US-UK.svg"
import appStoreID from "../images/appstore/app-store-ID.svg"

import playStoreEN from "../images/googleplay/google-play-badge-US.svg"
import playStoreID from "../images/googleplay/google-play-badge-ID.svg"

const appStoreLink = "https://apps.apple.com/id/app/gojek/id944875099"
const playStoreLink = "https://play.google.com/store/apps/details?id=com.gojek.app"

const Header = ({ body, locale }) => {

  const asLogo = (locale === 'en') ? appStoreEN : appStoreID
  const gpLogo = (locale === 'en') ? playStoreEN : playStoreID

  const android = <a href={playStoreLink}><img alt="Gojek on Google Play" src={gpLogo} /></a>
  const iOS = <a href={appStoreLink}><img alt="Gojek on the App Store" src={asLogo} /></a>

  const appLogo = () => {
    if (isIOS) return iOS
    if (isAndroid) return android

    return (
      <>
        {iOS}
        {android}
      </>
    )
  }
  
  return (
    <header className="header">
      <div className="header-container">
        <img alt="Gojek" src={logo} />
        <p>{body}</p>
        <div className="app-logo">
          { appLogo() }
        </div>
      </div>
    </header>
  )
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header