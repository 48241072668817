import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import { isIOS } from "react-device-detect";

const IndexPage = ({ data, pageContext}) => {
  return (
    <Layout {...pageContext} >
      <SEO title="Gojek" />
      <div className="body-bg">
        <div className="image-container">
          {
            isIOS ?
              <Img fluid={data.appImage.childImageSharp.fluid} alt="Gojek App"/> : 
              <Img fluid={data.appImageAndroid.childImageSharp.fluid} alt="Gojek App"/>
          }
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const IndexPageQuery = graphql`
  query IndexPageQuery {
    appImage: file(relativePath: { eq: "app-image@4x.png" }) {
      childImageSharp {
        fluid(maxWidth: 446) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    appImageAndroid: file(relativePath: { eq: "app-image-android@4x.png" }) {
      childImageSharp {
        fluid(maxWidth: 446) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`